import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { AppConfig } from '@dougs/core/config';
import { PaymentsProcessor, PaymentsProcessorConfiguration, PaymentsProcessorPost } from '@dougs/ecommerce/dto';

@Injectable({
  providedIn: 'root',
})
export class PaymentsProcessorHttpService {
  constructor(private readonly http: HttpClient) {}

  getPaymentsProcessors(company: Company): Observable<PaymentsProcessor[]> {
    return this.http.get<PaymentsProcessor[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${company.id}/paymentsProcessors`,
    );
  }

  getPaymentsProcessorsConfigurationList(): Observable<PaymentsProcessorConfiguration[]> {
    return this.http.get<PaymentsProcessorConfiguration[]>(
      `${AppConfig.settings.legacyApiServerUrl}/paymentsProcessors`,
    );
  }

  createPaymentsProcessor(company: Company, paymentsProcessor: PaymentsProcessorPost): Observable<PaymentsProcessor> {
    return this.http.post<PaymentsProcessor>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${company.id}/paymentsProcessors`,
      paymentsProcessor,
    );
  }
}
