import { PaymentsProcessorWithDate, PaymentsProcessorWithDatePost } from './payments-processor.dto';

export interface SalesChannel {
  id: number;
  memo?: string;
  companyId: number;
  type: string;
  shopUrl?: string;
  category: SalesChannelCategory;
  name: string;
  endDate?: Date;
  paymentsProcessorsWithDate?: PaymentsProcessorWithDate[];
  sellsMerchandise: boolean;
  sellsFinishedProduct: boolean;
  salesServices: boolean;
  fromFrance: boolean;
  fromEu: boolean;
  fromOutsideEu: boolean;
  accountNumber: string | undefined;
}

export interface SalesChannelPost {
  id?: string;
  type: string;
  shopUrl?: string;
  category: string;
  memo?: string;
  endDate?: Date | null;
  paymentsProcessorsWithDate: PaymentsProcessorWithDatePost[];
  sellsMerchandise: boolean;
  sellsFinishedProduct: boolean;
  salesServices: boolean;
  fromFrance: boolean;
  fromEu: boolean;
  fromOutsideEu: boolean;
}

export interface MarketPlaceConfiguration {
  value: string;
  label: string;
  useDefaultPaymentsProcessor: boolean;
  allowCustomPaymentsProcessor: boolean;
  disabledDate?: Date;
}

export interface EcommerceWebsiteConfiguration {
  value: string;
  label: string;
}

export enum SalesChannelCategory {
  WEBSITE = 'website',
  PHYSICAL = 'physicalPlace',
  MARKETPLACE = 'marketPlace',
}
