export type EcommerceShopSyncConfig = {
  [key: string]: EcommerceShopSyncConfigData;
};

export interface EcommerceShopSyncConfigData {
  shopTypeLabel: string;
  syncMode: EcommerceShopSyncMode;
  helpModalBody: string;
  urlPlaceholder: string;
  mandatoryCompanyFlag?: string;
}

export enum EcommerceShopSyncMode {
  URL,
  KEYS,
}
