import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import {
  DestinationZone,
  EcommerceSale,
  EcommerceSaleCreate,
  EcommerceSaleErrors,
  EcommerceSalePost,
  EcommerceSales,
  EcommerceSaleSubSection,
  EcommerceVatExemptionReason,
  ExpenseType,
  OriginZone,
  PackageType,
  PaymentProcessorInfo,
  SalesChannel,
  SpecificVatRateReason,
  Zone,
} from '@dougs/ecommerce/dto';
import { Operation } from '@dougs/operations/dto';

@Injectable({
  providedIn: 'root',
})
export class EcommerceSalesHttpService {
  constructor(private readonly http: HttpClient) {}

  refreshEcommerceSales(operation: Operation): Observable<EcommerceSales> {
    return this.http.get<EcommerceSales>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${operation.companyId}/operations/${operation.id}/ecommerceSales`,
    );
  }

  updateEcommerceSale(operation: Operation, ecommerceSale: EcommerceSalePost): Observable<EcommerceSalePost> {
    return this.http.post<EcommerceSalePost>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${operation.companyId}/operations/${operation.id}/ecommerceSales/${ecommerceSale.breakdownId}`,
      { ecommerceSale },
    );
  }

  createEcommerceSale(operation: Operation, ecommerceSaleCreate: EcommerceSaleCreate): Observable<EcommerceSale> {
    return this.http.post<EcommerceSale>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${operation.companyId}/operations/${operation.id}/ecommerceSales`,
      { ecommerceSale: ecommerceSaleCreate },
    );
  }

  createEcommerceSaleSubSection(
    operation: Operation,
    ecommerceSaleCreate: EcommerceSaleCreate,
    ecommerceSales: EcommerceSales,
  ): Observable<EcommerceSaleSubSection> {
    return this.http.post<EcommerceSaleSubSection>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${operation.companyId}/operations/${operation.id}/ecommerceSales/create-sub-section`,
      { ecommerceSale: ecommerceSaleCreate, ecommerceSales },
    );
  }

  deleteEcommerceSale(operation: Operation, ecommerceSale: EcommerceSalePost): Observable<EcommerceSalePost> {
    return this.http.delete<EcommerceSalePost>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${operation.companyId}/operations/${operation.id}/ecommerceSales/${ecommerceSale.breakdownId}`,
      {
        body: { ecommerceSale },
      },
    );
  }

  deleteEcommerceSubSection(
    operation: Operation,
    ecommerceSale: EcommerceSalePost,
    ecommerceSales: EcommerceSales,
  ): Observable<EcommerceSalePost> {
    return this.http.delete<EcommerceSalePost>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${operation.companyId}/operations/${operation.id}/ecommerceSales/delete-sub-section`,
      {
        body: { ecommerceSale, ecommerceSales },
      },
    );
  }

  getDestinationCountries(
    operation: Operation,
    sectionId: string,
    subSectionId: string,
  ): Observable<DestinationZone[]> {
    return this.http.get<DestinationZone[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${operation.companyId}/operations/${operation.id}/associations/countries-vat-rate`,
      { params: new HttpParams().append('sectionId', sectionId).append('subSectionId', subSectionId) },
    );
  }

  getPackageType(operation: Operation): Observable<PackageType[]> {
    return this.http.get<PackageType[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${operation.companyId}/operations/${operation.id}/associations/package-types`,
    );
  }

  getOriginZone(operation: Operation): Observable<OriginZone> {
    return this.http.get<OriginZone>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${operation.companyId}/operations/${operation.id}/associations/countries`,
    );
  }

  getVatExemptionReasons(operation: Operation): Observable<EcommerceVatExemptionReason[]> {
    return this.http.get<EcommerceVatExemptionReason[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${operation.companyId}/operations/${operation.id}/associations/vat-exemption-reasons`,
    );
  }

  getExpensesType(operation: Operation): Observable<ExpenseType[]> {
    return this.http.get<ExpenseType[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${operation.companyId}/operations/${operation.id}/associations/expense-types`,
    );
  }

  getPaymentProcessors(operation: Operation): Observable<PaymentProcessorInfo[]> {
    return this.http.get<PaymentProcessorInfo[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${operation.companyId}/operations/${operation.id}/associations/associated-payments-processors`,
    );
  }

  getZones(operation: Operation): Observable<Zone[]> {
    return this.http.get<Zone[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${operation.companyId}/operations/${operation.id}/associations/destination-zones`,
    );
  }

  getSpecificVatRateReasons(operation: Operation): Observable<SpecificVatRateReason[]> {
    return this.http.get<SpecificVatRateReason[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${operation.companyId}/operations/${operation.id}/associations/specific-vat-rate-reasons`,
    );
  }

  getEcommerceErrorReport(operation: Operation, salesChannel: SalesChannel): Observable<EcommerceSaleErrors> {
    return this.http.get<EcommerceSaleErrors>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${operation.companyId}/salesChannels/${salesChannel.id}/operations/${operation.id}/error-report`,
    );
  }
}
