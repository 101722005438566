import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { LoggerService } from '@dougs/core/logger';
import { SubscriptionPlan } from '@dougs/subscription/dto';
import { UserStateService } from '@dougs/user/shared';
import { EcommerceHttpService } from '../http/ecommerce.http';

@Injectable({
  providedIn: 'root',
})
export class EcommerceStateService {
  constructor(
    private readonly logger: LoggerService,
    private readonly userStateService: UserStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly ecommerceHttpService: EcommerceHttpService,
  ) {}

  async enableSalesViaPlatform(company: Company): Promise<void> {
    try {
      this.companyStateService.setCompanyState(
        await lastValueFrom(this.ecommerceHttpService.enableSalesViaPlatform(company.id)),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async disableSalesViaPlatform(company: Company): Promise<void> {
    try {
      this.companyStateService.setCompanyState(
        await lastValueFrom(this.ecommerceHttpService.disableSalesViaPlatform(company.id)),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async enablePlatformCommission(company: Company): Promise<void> {
    try {
      this.companyStateService.setCompanyState(
        await lastValueFrom(this.ecommerceHttpService.enablePlatformCommission(company.id)),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async disablePlatformCommission(company: Company): Promise<void> {
    try {
      this.companyStateService.setCompanyState(
        await lastValueFrom(this.ecommerceHttpService.disablePlatformCommission(company.id)),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async enableIndividualEuropeanSales(company: Company): Promise<void> {
    try {
      this.companyStateService.setCompanyState(
        await lastValueFrom(this.ecommerceHttpService.enableIndividualEuropeanSales(company.id)),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async disableIndividualEuropeanSales(company: Company): Promise<void> {
    try {
      this.companyStateService.setCompanyState(
        await lastValueFrom(this.ecommerceHttpService.disableIndividualEuropeanSales(company.id)),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async enableDropShippingOver150(company: Company): Promise<void> {
    try {
      this.companyStateService.setCompanyState(
        await lastValueFrom(this.ecommerceHttpService.enableDropShippingOver150(company.id)),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async disableDropShippingOver150(company: Company): Promise<void> {
    try {
      this.companyStateService.setCompanyState(
        await lastValueFrom(this.ecommerceHttpService.disableDropShippingOver150(company.id)),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async enableDropShippingUnder150FromPersonalWeb(company: Company): Promise<void> {
    try {
      this.companyStateService.setCompanyState(
        await lastValueFrom(this.ecommerceHttpService.enableDropShippingUnder150FromPersonalWeb(company.id)),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async disableDropShippingUnder150FromPersonalWeb(company: Company): Promise<void> {
    try {
      this.companyStateService.setCompanyState(
        await lastValueFrom(this.ecommerceHttpService.disableDropShippingUnder150FromPersonalWeb(company.id)),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async enableDropShippingUnder150FromMarketPlace(company: Company): Promise<void> {
    try {
      this.companyStateService.setCompanyState(
        await lastValueFrom(this.ecommerceHttpService.enableDropShippingUnder150FromMarketPlace(company.id)),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async disableDropShippingUnder150FromMarketPlace(company: Company): Promise<void> {
    try {
      this.companyStateService.setCompanyState(
        await lastValueFrom(this.ecommerceHttpService.disableDropShippingUnder150FromMarketPlace(company.id)),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async enableIndividualSalesFromUeStock(company: Company): Promise<void> {
    try {
      this.companyStateService.setCompanyState(
        await lastValueFrom(this.ecommerceHttpService.enableIndividualSalesFromUeStock(company.id)),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async disableIndividualSalesFromUeStock(company: Company): Promise<void> {
    try {
      this.companyStateService.setCompanyState(
        await lastValueFrom(this.ecommerceHttpService.disableIndividualSalesFromUeStock(company.id)),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async enableIndividualSalesFromFrenchStock(company: Company): Promise<void> {
    try {
      this.companyStateService.setCompanyState(
        await lastValueFrom(this.ecommerceHttpService.enableIndividualSalesFromFrenchStock(company.id)),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async disableIndividualSalesFromFrenchStock(company: Company): Promise<void> {
    try {
      this.companyStateService.setCompanyState(
        await lastValueFrom(this.ecommerceHttpService.disableIndividualSalesFromFrenchStock(company.id)),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async enableIndividualServicesEuSales(company: Company): Promise<void> {
    try {
      this.companyStateService.setCompanyState(
        await lastValueFrom(this.ecommerceHttpService.enableIndividualServicesEuSales(company.id)),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async disableIndividualServicesEuSales(company: Company): Promise<void> {
    try {
      this.companyStateService.setCompanyState(
        await lastValueFrom(this.ecommerceHttpService.disableIndividualServicesEuSales(company.id)),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async enableEuIndividualRevenueOver10000(company: Company): Promise<void> {
    try {
      this.companyStateService.setCompanyState(
        await lastValueFrom(this.ecommerceHttpService.enableEuIndividualRevenueOver10000(company.id)),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async disableEuIndividualRevenueOver10000(company: Company): Promise<void> {
    try {
      this.companyStateService.setCompanyState(
        await lastValueFrom(this.ecommerceHttpService.disableEuIndividualRevenueOver10000(company.id)),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async enableForceOssWithOption(company: Company): Promise<void> {
    try {
      this.companyStateService.setCompanyState(
        await lastValueFrom(this.ecommerceHttpService.enableForceOssWithOption(company.id)),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async disableForceOssWithOption(company: Company): Promise<void> {
    try {
      this.companyStateService.setCompanyState(
        await lastValueFrom(this.ecommerceHttpService.disableForceOssWithOption(company.id)),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async lockSalesViaPlatformConfiguration(inputCompany: Company): Promise<SubscriptionPlan | null> {
    try {
      const { company, newPlan } = await lastValueFrom(this.ecommerceHttpService.lock(inputCompany.id));
      this.companyStateService.setCompanyState(company);
      return newPlan;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async unlockSalesViaPlatformConfiguration(company: Company): Promise<void> {
    try {
      this.companyStateService.setCompanyState(await lastValueFrom(this.ecommerceHttpService.unlock(company.id)));
    } catch (e) {
      this.logger.error(e);
    }
  }

  async deactivateEcommerceExtension(company: Company): Promise<void> {
    try {
      this.companyStateService.setCompanyState(
        await lastValueFrom(this.ecommerceHttpService.deactivateEcommerceOption(company.id)),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async activateEcommerceExtension(company: Company): Promise<void> {
    try {
      this.companyStateService.setCompanyState(
        await lastValueFrom(this.ecommerceHttpService.reactivateEcommerceOption(company.id)),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async lockDateSalesViaPlatformConfiguration(company: Company): Promise<void> {
    try {
      this.companyStateService.setCompanyState(await lastValueFrom(this.ecommerceHttpService.lockDate(company.id)));
    } catch (e) {
      this.logger.error(e);
    }
  }

  async unlockDateSalesViaPlatformConfiguration(company: Company): Promise<void> {
    try {
      this.companyStateService.setCompanyState(await lastValueFrom(this.ecommerceHttpService.unlockDate(company.id)));
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updateOssActivationDate(company: Company, date: string | null): Promise<boolean> {
    try {
      this.companyStateService.setCompanyState(
        await lastValueFrom(this.ecommerceHttpService.updateOssActivationDate(company.id, date)),
      );

      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async updateIOssActivationDate(company: Company, date: string | null): Promise<boolean> {
    try {
      this.companyStateService.setCompanyState(
        await lastValueFrom(this.ecommerceHttpService.updateIOssActivationDate(company.id, date)),
      );

      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }
}
