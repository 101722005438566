import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { ShopifyAuthenticatedSession } from '@dougs/ecommerce/dto';
import { ShopifyHttp } from '../http';

@Injectable({
  providedIn: 'root',
})
export class ShopifyStateService extends StateService<void> {
  constructor(
    private readonly shopifyHttp: ShopifyHttp,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  async isAuthenticated(companyId: number, salesChannelId: number): Promise<ShopifyAuthenticatedSession> {
    try {
      return await lastValueFrom(this.shopifyHttp.isAuthenticated(companyId, salesChannelId));
    } catch (e) {
      this.logger.error(e);
      return {
        isAuthenticated: false,
        shopUrl: '',
      };
    }
  }

  async resyncData(companyId: number, salesChannelId: number): Promise<void> {
    try {
      await lastValueFrom(this.shopifyHttp.resyncData(companyId, salesChannelId));
    } catch (e) {
      this.logger.error(e);
    }
  }
}
