import { Injectable } from '@angular/core';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { toPromise } from '@dougs/core/utils';
import { EcommerceShopSynchronizeFields, EcommerceShopSynchronizeResult, SalesChannel } from '@dougs/ecommerce/dto';
import { EcommerceShopSyncHttp } from '../http/ecommerce-shop-sync.http';

@Injectable({
  providedIn: 'root',
})
export class EcommerceShopSyncStateService extends StateService<void> {
  constructor(
    private readonly logger: LoggerService,
    private readonly ecommerceShopSyncHttp: EcommerceShopSyncHttp,
  ) {
    super();
  }

  async synchronize(
    salesChannel: SalesChannel,
    fields: EcommerceShopSynchronizeFields,
  ): Promise<EcommerceShopSynchronizeResult | null> {
    try {
      const shopSyncResult: EcommerceShopSynchronizeResult = await toPromise(
        this.ecommerceShopSyncHttp.synchronize(salesChannel.id, salesChannel.companyId, fields),
      );
      return shopSyncResult;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }
}
