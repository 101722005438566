import { Company } from '@dougs/company/dto';
import { EcommerceShopSyncConfig, EcommerceShopSyncMode } from '@dougs/ecommerce/dto';

export const ecommerceShopSyncConfig: EcommerceShopSyncConfig = {
  shopify: {
    shopTypeLabel: 'Shopify',
    syncMode: EcommerceShopSyncMode.URL,
    urlPlaceholder: 'Ex: https://myshop.myshopify.com',
    helpModalBody:
      "Saisissez l'adresse de votre boutique Shopify.<br>" +
      'Retrouvez-la depuis <b>Shopify > Paramètres > Domaines</b><br>' +
      "Utilisez l'adresse finissant par <i>myshopify.com</i>",
  },
  wooCommerce: {
    shopTypeLabel: 'WooCommerce',
    syncMode: EcommerceShopSyncMode.KEYS,
    urlPlaceholder: 'Ex: https://maboutique.com',
    helpModalBody: "Saisissez l'adresse de votre boutique WooCommerce.",
    mandatoryCompanyFlag: 'beta-woocommerce',
  },
};

export const isShopSynchronizable = (shopType: string): boolean => {
  return Object.keys(ecommerceShopSyncConfig).includes(shopType);
};

export const canSynchronizeShop = (shopType: string, company: Company): boolean => {
  const isSynchonizable: boolean = isShopSynchronizable(shopType);

  if (!isSynchonizable) {
    return false;
  }

  return (
    (!ecommerceShopSyncConfig[shopType].mandatoryCompanyFlag ||
      company.flags.includes(ecommerceShopSyncConfig[shopType].mandatoryCompanyFlag!)) &&
    company.accountingConfiguration.hasEcommerceDispatch
  );
};
