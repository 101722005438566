import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import {
  DestinationZone,
  EcommerceSale,
  EcommerceSaleCreate,
  EcommerceSaleErrors,
  EcommerceSalePost,
  EcommerceSales,
  EcommerceSaleSubSection,
  EcommerceVatExemptionReason,
  ExpenseType,
  OriginZone,
  PackageType,
  PaymentProcessorInfo,
  SalesChannel,
  SpecificVatRateReason,
  Zone,
} from '@dougs/ecommerce/dto';
import { Operation } from '@dougs/operations/dto';
import { EcommerceSalesHttpService } from '../http/ecommerce-sales.http';
import { EcommerceSaleService } from '../services';

interface EcommerceSaleState {
  sales: EcommerceSales;
  salesErrors: EcommerceSaleErrors;
}

@Injectable({
  providedIn: 'root',
})
export class EcommerceSaleStateService extends StateService<EcommerceSaleState> {
  constructor(
    private readonly ecommerceSalesHttpService: EcommerceSalesHttpService,
    private readonly ecommerceSaleService: EcommerceSaleService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  readonly sales$: Observable<EcommerceSales> = this.select((state) => state.sales);
  readonly salesErrors$: Observable<EcommerceSaleErrors> = this.select((state) => state.salesErrors);

  public async refreshEcommerceSales(operation: Operation): Promise<void> {
    try {
      const ecommerceSales: EcommerceSales = await lastValueFrom(
        this.ecommerceSalesHttpService.refreshEcommerceSales(operation),
      );
      this.setState({
        sales: ecommerceSales,
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  public async updateEcommerceSale(
    operation: Operation,
    ecommerceSale: EcommerceSalePost,
  ): Promise<EcommerceSales | null> {
    try {
      const ecommerceSaleUpdated: EcommerceSalePost | null = await lastValueFrom(
        this.ecommerceSalesHttpService.updateEcommerceSale(operation, ecommerceSale),
      );
      if (ecommerceSaleUpdated) {
        const ecommerceSales: EcommerceSales | null = this.ecommerceSaleService.updateSaleItemInEcommerceSales(
          this.state.sales,
          ecommerceSaleUpdated,
        );
        if (ecommerceSales) {
          this.setState({
            sales: ecommerceSales,
          });
        }
        return ecommerceSales;
      }
      return null;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  public async createEcommerceSale(
    operation: Operation,
    ecommerceSaleToCreate: EcommerceSaleCreate,
  ): Promise<EcommerceSales | void | null> {
    try {
      const ecommerceSaleCreated: EcommerceSale = await lastValueFrom(
        this.ecommerceSalesHttpService.createEcommerceSale(operation, ecommerceSaleToCreate),
      );
      if (ecommerceSaleCreated) {
        const updatedEcommerceSales: EcommerceSales | null = this.ecommerceSaleService.createSaleItemInEcommerceSales(
          this.state.sales,
          ecommerceSaleCreated,
          ecommerceSaleToCreate.sectionId,
          ecommerceSaleToCreate.subSectionId,
        );
        if (updatedEcommerceSales) {
          this.setState({ sales: updatedEcommerceSales });
        }
      }
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  public async createEcommerceSaleSubSection(
    operation: Operation,
    ecommerceSaleToCreate: EcommerceSaleCreate,
  ): Promise<EcommerceSaleSubSection | void | null> {
    try {
      const ecommerceSaleSubSectionCreated: EcommerceSaleSubSection = await lastValueFrom(
        this.ecommerceSalesHttpService.createEcommerceSaleSubSection(
          operation,
          ecommerceSaleToCreate,
          this.state.sales ?? {},
        ),
      );
      if (ecommerceSaleSubSectionCreated) {
        const updatedEcommerceSales: EcommerceSales | null =
          this.ecommerceSaleService.createSaleSubSectionInEcommerceSales(
            this.state.sales,
            ecommerceSaleToCreate.sectionId,
            ecommerceSaleSubSectionCreated,
          );
        if (updatedEcommerceSales) {
          this.setState({ sales: updatedEcommerceSales });
        }
      }
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  public async deleteEcommerceSale(operation: Operation, ecommerceSale: EcommerceSalePost): Promise<boolean> {
    try {
      const deletedEcommerceSale: EcommerceSalePost = await lastValueFrom(
        this.ecommerceSalesHttpService.deleteEcommerceSale(operation, ecommerceSale),
      );
      const updatedEcommerceSales: EcommerceSales | null = this.ecommerceSaleService.deleteSaleItemInEcommerceSales(
        this.state.sales,
        deletedEcommerceSale,
      );
      if (updatedEcommerceSales) {
        this.setState({ sales: updatedEcommerceSales });
      }
      return !!deletedEcommerceSale;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  public async deleteEcommerceSubSection(operation: Operation, ecommerceSale: EcommerceSalePost): Promise<boolean> {
    try {
      const deletedEcommerceSubSection: EcommerceSalePost = await lastValueFrom(
        this.ecommerceSalesHttpService.deleteEcommerceSubSection(operation, ecommerceSale, this.state.sales ?? {}),
      );
      if (deletedEcommerceSubSection) {
        const updatedEcommerceSales: EcommerceSales | null = this.ecommerceSaleService.deleteSubSectionInEcommerceSales(
          this.state.sales,
          deletedEcommerceSubSection,
        );
        if (updatedEcommerceSales) {
          this.setState({ sales: updatedEcommerceSales });
        }
      }
      return !!deletedEcommerceSubSection;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  public async getDestinationCountries(
    operation: Operation,
    sectionId: string,
    subSectionId: string,
  ): Promise<DestinationZone[]> {
    try {
      return await lastValueFrom(
        this.ecommerceSalesHttpService.getDestinationCountries(operation, sectionId, subSectionId),
      );
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }

  public async getPackageType(operation: Operation): Promise<PackageType[]> {
    try {
      return await lastValueFrom(this.ecommerceSalesHttpService.getPackageType(operation));
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }

  public async getOriginZone(operation: Operation): Promise<OriginZone | null> {
    try {
      return await lastValueFrom(this.ecommerceSalesHttpService.getOriginZone(operation));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  public async getVatExemptionReasons(operation: Operation): Promise<EcommerceVatExemptionReason[]> {
    try {
      return await lastValueFrom(this.ecommerceSalesHttpService.getVatExemptionReasons(operation));
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }

  public async getExpensesType(operation: Operation): Promise<ExpenseType[]> {
    try {
      return await lastValueFrom(this.ecommerceSalesHttpService.getExpensesType(operation));
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }

  public async getPaymentProcessors(operation: Operation): Promise<PaymentProcessorInfo[]> {
    try {
      return await lastValueFrom(this.ecommerceSalesHttpService.getPaymentProcessors(operation));
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }

  public async getDestinationZones(operation: Operation): Promise<Zone[]> {
    try {
      return await lastValueFrom(this.ecommerceSalesHttpService.getZones(operation));
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }

  public async getSpecificVatRateReasons(operation: Operation): Promise<SpecificVatRateReason[]> {
    try {
      return await lastValueFrom(this.ecommerceSalesHttpService.getSpecificVatRateReasons(operation));
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }

  public async getEcommerceErrorReport(
    operation: Operation,
    salesChannel: SalesChannel,
  ): Promise<EcommerceSaleErrors | null> {
    try {
      const salesErrors = await lastValueFrom(
        this.ecommerceSalesHttpService.getEcommerceErrorReport(operation, salesChannel),
      );
      this.setState({
        salesErrors,
      });
      return salesErrors;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  public resetEcommerceSales(): void {
    this.setState({
      sales: undefined,
    });
  }
}
