import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { AppConfig } from '@dougs/core/config';
import {
  ConnectSalesChannelFields,
  EcommerceWebsiteConfiguration,
  MarketPlaceConfiguration,
  SalesChannel,
  SalesChannelPost,
} from '@dougs/ecommerce/dto';

@Injectable({
  providedIn: 'root',
})
export class SalesChannelHttpService {
  constructor(private readonly http: HttpClient) {}

  getSalesChannels(companyId: number): Observable<SalesChannel[]> {
    return this.http.get<SalesChannel[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesChannels`,
    );
  }

  getMarketPlacesConfigurationList(): Observable<MarketPlaceConfiguration[]> {
    return this.http.get<MarketPlaceConfiguration[]>(`${AppConfig.settings.legacyApiServerUrl}/marketPlacesList`);
  }

  getEcommerceWebsiteSoftwareConfigurationList(): Observable<EcommerceWebsiteConfiguration[]> {
    return this.http.get<EcommerceWebsiteConfiguration[]>(
      `${AppConfig.settings.legacyApiServerUrl}/eCommerceWebsiteSoftwares`,
    );
  }

  updateSalesChannel(company: Company, salesChannel: SalesChannelPost): Observable<SalesChannel> {
    return this.http.post<SalesChannel>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${company.id}/salesChannels/${salesChannel.id}`,
      salesChannel,
    );
  }

  createSalesChannel(company: Company, salesChannelToCreate: SalesChannelPost): Observable<SalesChannel> {
    return this.http.post<SalesChannel>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${company.id}/salesChannels`,
      salesChannelToCreate,
    );
  }

  deleteSalesChannel(salesChannelToDelete: SalesChannel): Observable<SalesChannel> {
    return this.http.delete<SalesChannel>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${salesChannelToDelete.companyId}/salesChannels/${salesChannelToDelete.id}`,
    );
  }

  connectSalesChannel(salesChannel: SalesChannel, fields: ConnectSalesChannelFields): Observable<SalesChannel> {
    return this.http.post<SalesChannel>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${salesChannel.companyId}/salesChannels/${salesChannel.id}/actions/connect`,
      {
        fields,
      },
    );
  }

  authorizeSalesChannel(salesChannel: SalesChannel): Observable<{ authUrl: string }> {
    return this.http.post<{ authUrl: string }>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${salesChannel.companyId}/salesChannels/${salesChannel.id}/actions/authorize`,
      {},
    );
  }
}
