import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { AppConfig } from '@dougs/core/config';
import { SubscriptionPlan } from '@dougs/subscription/dto';

@Injectable({
  providedIn: 'root',
})
export class EcommerceHttpService {
  constructor(private readonly http: HttpClient) {}

  enableSalesViaPlatform(companyId: number): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/enableSalesViaPlatform`,
      {},
    );
  }

  disableSalesViaPlatform(companyId: number): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/disableSalesViaPlatform`,
      {},
    );
  }

  enablePlatformCommission(companyId: number): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/enablePlatformCommission`,
      {},
    );
  }

  disablePlatformCommission(companyId: number): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/disablePlatformCommission`,
      {},
    );
  }

  enableIndividualEuropeanSales(companyId: number): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/enableIndividualEuropeanSales`,
      {},
    );
  }

  disableIndividualEuropeanSales(companyId: number): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/disableIndividualEuropeanSales`,
      {},
    );
  }

  enableDropShippingOver150(companyId: number): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/enableDropShippingOver150`,
      {},
    );
  }

  disableDropShippingOver150(companyId: number): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/disableDropShippingOver150`,
      {},
    );
  }

  enableDropShippingUnder150FromPersonalWeb(companyId: number): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/enableDropShippingUnder150FromPersonalWeb`,
      {},
    );
  }

  disableDropShippingUnder150FromPersonalWeb(companyId: number): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/disableDropShippingUnder150FromPersonalWeb`,
      {},
    );
  }

  enableDropShippingUnder150FromMarketPlace(companyId: number): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/enableDropShippingUnder150FromMarketPlace`,
      {},
    );
  }

  disableDropShippingUnder150FromMarketPlace(companyId: number): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/disableDropShippingUnder150FromMarketPlace`,
      {},
    );
  }

  enableIndividualSalesFromUeStock(companyId: number): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/enableIndividualSalesFromUeStock`,
      {},
    );
  }

  disableIndividualSalesFromUeStock(companyId: number): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/disableIndividualSalesFromUeStock`,
      {},
    );
  }

  enableIndividualSalesFromFrenchStock(companyId: number): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/enableIndividualSalesFromFrenchStock`,
      {},
    );
  }

  disableIndividualSalesFromFrenchStock(companyId: number): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/disableIndividualSalesFromFrenchStock`,
      {},
    );
  }

  enableIndividualServicesEuSales(companyId: number): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/enableIndividualServicesEuSales`,
      {},
    );
  }

  disableIndividualServicesEuSales(companyId: number): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/disableIndividualServicesEuSales`,
      {},
    );
  }

  enableEuIndividualRevenueOver10000(companyId: number): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/enableEuIndividualRevenueOver10000`,
      {},
    );
  }

  disableEuIndividualRevenueOver10000(companyId: number): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/disableEuIndividualRevenueOver10000`,
      {},
    );
  }

  enableForceOssWithOption(companyId: number): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/enableForceOssWithOption`,
      {},
    );
  }

  disableForceOssWithOption(companyId: number): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/disableForceOssWithOption`,
      {},
    );
  }

  updateOssActivationDate(companyId: number, date: string | null): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/ossActivationDate`,
      {
        ossActivationDate: date,
      },
    );
  }

  updateIOssActivationDate(companyId: number, date: string | null): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/iossActivationDate`,
      {
        iossActivationDate: date,
      },
    );
  }

  lock(companyId: number): Observable<{ company: Company; newPlan: SubscriptionPlan | null }> {
    return this.http.post<{ company: Company; newPlan: SubscriptionPlan }>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/lock`,
      {},
    );
  }

  unlock(companyId: number): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/unlock`,
      {},
    );
  }

  lockDate(companyId: number): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/lockDate`,
      {},
    );
  }

  unlockDate(companyId: number): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/unlockDate`,
      {},
    );
  }

  deactivateEcommerceOption(companyId: number): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/deactivateEcommerceOption`,
      {},
    );
  }

  reactivateEcommerceOption(companyId: number): Observable<Company> {
    return this.http.post<Company>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesViaPlatformConfiguration/reactivateEcommerceOption`,
      {},
    );
  }
}
