import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';

import { Company } from '@dougs/company/dto';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { PaymentsProcessor, PaymentsProcessorConfiguration, PaymentsProcessorPost } from '@dougs/ecommerce/dto';
import { PaymentsProcessorHttpService } from '../http/payments-processor.http';

interface PaymentsProcessorState {
  paymentsProcessors: PaymentsProcessor[];
  paymentsProcessorsConfigurationList: PaymentsProcessorConfiguration[];
}

@Injectable({
  providedIn: 'root',
})
export class PaymentsProcessorStateService extends StateService<PaymentsProcessorState> {
  constructor(
    private readonly logger: LoggerService,
    private readonly paymentsProcessorHttpService: PaymentsProcessorHttpService,
  ) {
    super();
  }

  readonly paymentsProcessors$: Observable<PaymentsProcessor[]> = this.select((state) => state.paymentsProcessors);

  readonly paymentsProcessorsConfigurationList$: Observable<PaymentsProcessorConfiguration[]> = this.select(
    (state) => state.paymentsProcessorsConfigurationList,
  );

  async refreshPaymentsProcessors(company: Company): Promise<void> {
    try {
      this.setState({
        paymentsProcessors: this.getAndSetCacheState('paymentsProcessors', company.id)
          ? this.state.paymentsProcessors
          : await lastValueFrom(this.paymentsProcessorHttpService.getPaymentsProcessors(company)),
      });
    } catch (e) {
      this.clearCache('paymentsProcessors');
      this.logger.error(e);
    }
  }

  async refreshPaymentsProcessorsConfiguration(): Promise<void> {
    try {
      this.setState({
        paymentsProcessorsConfigurationList:
          this.state && this.getCacheState('paymentsProcessorsConfiguration')
            ? this.state.paymentsProcessorsConfigurationList
            : await lastValueFrom(this.paymentsProcessorHttpService.getPaymentsProcessorsConfigurationList()),
      });
    } catch (e) {
      this.clearCache('paymentsProcessorsConfiguration');
      this.logger.error(e);
    }
  }

  async createPaymentsProcessor(
    company: Company,
    paymentProcessor: PaymentsProcessorPost,
  ): Promise<PaymentsProcessor | undefined> {
    try {
      const createdPaymentProcessor = await lastValueFrom(
        this.paymentsProcessorHttpService.createPaymentsProcessor(company, paymentProcessor),
      );
      if (this.state.paymentsProcessors) {
        this.setState({
          paymentsProcessors: [...this.state.paymentsProcessors, createdPaymentProcessor],
        });
      }
      return createdPaymentProcessor;
    } catch (e) {
      this.logger.error(e);
    }
  }
}
