import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { ShopifyAuthenticatedSession } from '@dougs/ecommerce/dto';

@Injectable({ providedIn: 'root' })
export class ShopifyHttp {
  private readonly routes = {
    isAuthenticated: '/auth',
    deleteAuth: '/auth',
    refreshDispatch: '/refresh',
    resyncData: '/import',
  };

  constructor(private readonly http: HttpClient) {}

  deleteAuth(companyId: number, salesChannelId: number): Observable<void> {
    return this.http.delete<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesChannels/${salesChannelId}${this.routes.deleteAuth}`,
    );
  }

  isAuthenticated(companyId: number, salesChannelId: number): Observable<ShopifyAuthenticatedSession> {
    return this.http.get<ShopifyAuthenticatedSession>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesChannels/${salesChannelId}${this.routes.isAuthenticated}`,
    );
  }

  refreshDispatch(companyId: number, salesChannelId: number, operationId: number): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesChannels/${salesChannelId}/synchronization/operations/${operationId}${this.routes.refreshDispatch}`,
      {},
    );
  }

  resyncData(companyId: number, salesChannelId: number): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesChannels/${salesChannelId}/synchronization${this.routes.resyncData}`,
      {},
    );
  }
}
