import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Company } from '@dougs/company/dto';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import {
  AuthorizeSalesChannelResponse,
  ConnectSalesChannelFields,
  EcommerceWebsiteConfiguration,
  MarketPlaceConfiguration,
  SalesChannel,
  SalesChannelPost,
} from '@dougs/ecommerce/dto';
import { SalesChannelHttpService, ShopifyHttp } from '../http';

interface SalesChannelState {
  salesChannels: SalesChannel[];
  marketPlacesConfigurationList: MarketPlaceConfiguration[];
  ecommerceWebsiteSoftwareConfigurationList: EcommerceWebsiteConfiguration[];
}

@Injectable({
  providedIn: 'root',
})
export class SalesChannelStateService extends StateService<SalesChannelState> {
  constructor(
    private readonly logger: LoggerService,
    private readonly salesChannelHttpService: SalesChannelHttpService,
    private readonly shopifyHttp: ShopifyHttp,
  ) {
    super();
  }

  readonly salesChannels$: Observable<SalesChannel[]> = this.select((state) => state.salesChannels);
  readonly activeSalesChannels: Observable<SalesChannel[]> = this.salesChannels$.pipe(
    map((salesChannels) => salesChannels.filter((s) => !s.endDate)),
  );

  readonly inactiveSalesChannels: Observable<SalesChannel[]> = this.salesChannels$.pipe(
    map((salesChannels) => salesChannels.filter((s) => !!s.endDate)),
  );
  readonly shopifySalesChannels$: Observable<SalesChannel[]> = this.salesChannels$.pipe(
    map((salesChannels) => salesChannels.filter((s: SalesChannel) => s.type === 'shopify')),
  );
  readonly marketPlacesConfigurationListWithoutDisabled$: Observable<MarketPlaceConfiguration[] | undefined> =
    this.select((state) =>
      state.marketPlacesConfigurationList?.filter((config) => !config.disabledDate || config.disabledDate > new Date()),
    );
  readonly marketPlacesConfigurationListWithDisabled$: Observable<MarketPlaceConfiguration[] | undefined> = this.select(
    (state) => state.marketPlacesConfigurationList,
  );
  readonly ecommerceWebsiteSoftwareConfigurationList$: Observable<EcommerceWebsiteConfiguration[]> = this.select(
    (state) => state.ecommerceWebsiteSoftwareConfigurationList,
  );

  getCurrentSalesChannel(salesChannelId: number): Observable<SalesChannel | undefined> {
    return this.salesChannels$.pipe(
      map((salesChannels) => salesChannels.find((salesChannel) => salesChannel.id === salesChannelId)),
      take(1),
    );
  }

  async refreshSalesChannels(companyId: number): Promise<void> {
    try {
      this.setState({
        salesChannels: this.getAndSetCacheState('sales-channels', companyId)
          ? this.state.salesChannels
          : await lastValueFrom(this.salesChannelHttpService.getSalesChannels(companyId)),
      });
    } catch (e) {
      this.clearCache('sales-channels');
      this.logger.error(e);
    }
  }

  async refreshMarketPlacesConfiguration(): Promise<void> {
    try {
      this.setState({
        marketPlacesConfigurationList: this.getAndSetCacheState('marketPlaces')
          ? this.state.marketPlacesConfigurationList
          : await lastValueFrom(this.salesChannelHttpService.getMarketPlacesConfigurationList()),
      });
    } catch (e) {
      this.clearCache('marketPlaces');
      this.logger.error(e);
    }
  }

  async refreshEcommerceWebsiteSoftwareConfiguration(): Promise<void> {
    try {
      this.setState({
        ecommerceWebsiteSoftwareConfigurationList: this.getAndSetCacheState('ecommerceWebsiteSoftware')
          ? this.state.ecommerceWebsiteSoftwareConfigurationList
          : await lastValueFrom(this.salesChannelHttpService.getEcommerceWebsiteSoftwareConfigurationList()),
      });
    } catch (e) {
      this.clearCache('ecommerceWebsiteSoftware');
      this.logger.error(e);
    }
  }

  async createSalesChannel(company: Company, salesChannel: SalesChannelPost): Promise<SalesChannel | null> {
    try {
      const createdSalesChannel: SalesChannel = await lastValueFrom(
        this.salesChannelHttpService.createSalesChannel(company, salesChannel),
      );
      if (this.state.salesChannels) {
        this.setState({
          salesChannels: [...this.state.salesChannels, createdSalesChannel],
        });
      }
      return createdSalesChannel;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async updateSalesChannel(company: Company, salesChannel: SalesChannelPost): Promise<SalesChannel | null> {
    try {
      const updatedSalesChannel: SalesChannel = await lastValueFrom(
        this.salesChannelHttpService.updateSalesChannel(company, salesChannel),
      );
      this.setState({
        salesChannels: this.state.salesChannels.map((salesChannel) =>
          salesChannel.id === updatedSalesChannel.id ? updatedSalesChannel : salesChannel,
        ),
      });
      return updatedSalesChannel;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async deleteSalesChannel(salesChannel: SalesChannel): Promise<void> {
    try {
      await lastValueFrom(this.salesChannelHttpService.deleteSalesChannel(salesChannel));
      if (salesChannel.type === 'shopify') {
        await lastValueFrom(this.shopifyHttp.deleteAuth(salesChannel.companyId, salesChannel.id));
      }
      this.setState({
        salesChannels: this.state.salesChannels.filter(
          (salesChannelIterated) => salesChannel.id !== salesChannelIterated.id,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async deleteAuth(salesChannel: SalesChannel): Promise<void> {
    try {
      await lastValueFrom(this.shopifyHttp.deleteAuth(salesChannel.companyId, salesChannel.id));
      const updatedSalesChannel: SalesChannel = { ...salesChannel };
      this.setState({
        salesChannels: this.state.salesChannels.map((salesChannelIterated) =>
          salesChannelIterated.id === salesChannel.id ? updatedSalesChannel : salesChannelIterated,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async connectSalesChannel(
    salesChannel: SalesChannel,
    fields: ConnectSalesChannelFields,
  ): Promise<SalesChannel | null> {
    try {
      const connectedSalesChannel: SalesChannel = await lastValueFrom(
        this.salesChannelHttpService.connectSalesChannel(salesChannel, fields),
      );
      this.setState({
        salesChannels: this.state.salesChannels.map((salesChannel) =>
          salesChannel.id === connectedSalesChannel.id ? connectedSalesChannel : salesChannel,
        ),
      });
      return connectedSalesChannel;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async authorizeSalesChannel(salesChannel: SalesChannel): Promise<AuthorizeSalesChannelResponse> {
    try {
      return await lastValueFrom(this.salesChannelHttpService.authorizeSalesChannel(salesChannel));
    } catch (e) {
      this.logger.error(e);
      return { authUrl: null };
    }
  }
}
