import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { mergeObjects } from '@dougs/core/utils';
import {
  EcommerceSale,
  EcommerceSalePost,
  EcommerceSales,
  EcommerceSaleSection,
  EcommerceSaleSubSection,
} from '@dougs/ecommerce/dto';

@Injectable({
  providedIn: 'root',
})
export class EcommerceSaleService {
  public updateSaleItemInEcommerceSales(
    ecommerceSales: EcommerceSales,
    updatedSale: EcommerceSalePost,
  ): EcommerceSales | null {
    const currentSection: EcommerceSaleSection | undefined = ecommerceSales.sections.find(
      (section) => section.sectionId === updatedSale.sectionId,
    );
    if (!currentSection) {
      return null;
    }
    const currentSubSection: EcommerceSaleSubSection | undefined = currentSection?.subSections?.find(
      (subSection) => subSection.subSectionId === updatedSale.subSectionId,
    );
    if (!currentSubSection) {
      return null;
    }
    const currentSale: EcommerceSale | undefined = currentSubSection?.sales?.find(
      (sale) => sale.breakdownId === updatedSale.breakdownId,
    );

    if (!currentSale) {
      return null;
    }
    const sale: EcommerceSale = mergeObjects(currentSale, _.omit(updatedSale, 'sectionId', 'subSectionId'));
    const updatedSaleArray: EcommerceSale[] = currentSubSection?.sales.map((s) =>
      s.breakdownId === sale.breakdownId ? sale : s,
    );
    return this.getUpdatedEcommerceSalesFromUpdatedSales(
      updatedSaleArray,
      ecommerceSales,
      currentSubSection,
      currentSection,
      updatedSale,
    );
  }

  public createSaleItemInEcommerceSales(
    ecommerceSales: EcommerceSales,
    createdSale: EcommerceSale,
    sectionId: string,
    subSectionId: string,
  ): EcommerceSales | null {
    const currentSection: EcommerceSaleSection | undefined = ecommerceSales.sections.find(
      (section) => section.sectionId === sectionId,
    );
    if (!currentSection) {
      return null;
    }
    const currentSubSection: EcommerceSaleSubSection | undefined = currentSection?.subSections?.find(
      (subSection) => subSection.subSectionId === subSectionId,
    );
    if (!currentSubSection) {
      return null;
    }
    currentSubSection.sales.push(createdSale);
    const updatedSaleArray: EcommerceSale[] = currentSubSection.sales;
    return this.getUpdatedEcommerceSalesFromUpdatedSales(
      updatedSaleArray,
      ecommerceSales,
      currentSubSection,
      currentSection,
    );
  }

  createSaleSubSectionInEcommerceSales(
    ecommerceSales: EcommerceSales,
    sectionId: string,
    createdSubSection: EcommerceSaleSubSection,
  ) {
    const currentSection: EcommerceSaleSection | undefined = ecommerceSales.sections.find(
      (section) => section.sectionId === sectionId,
    );
    if (!currentSection) {
      return null;
    }
    currentSection.subSections.push(createdSubSection);
    const updatedSectionArray: EcommerceSaleSection[] = ecommerceSales.sections.map((section) =>
      section.sectionId === currentSection.sectionId ? currentSection : section,
    );
    const updatedEcommerceSales: EcommerceSales = {
      ...ecommerceSales,
      sections: updatedSectionArray,
      totalAmount: ecommerceSales.totalAmount,
      totalVatAmount: ecommerceSales.totalVatAmount,
    };
    return updatedEcommerceSales;
  }

  public deleteSaleItemInEcommerceSales(
    ecommerceSales: EcommerceSales,
    deletedSale: EcommerceSalePost,
  ): EcommerceSales | null {
    const currentSection: EcommerceSaleSection | undefined = ecommerceSales.sections.find(
      (section) => section.sectionId === deletedSale.sectionId,
    );
    if (!currentSection) {
      return null;
    }
    const currentSubSection: EcommerceSaleSubSection | undefined = currentSection?.subSections?.find(
      (subSection) => subSection.subSectionId === deletedSale.subSectionId,
    );
    if (!currentSubSection) {
      return null;
    }
    const updatedSaleArray: EcommerceSale[] = currentSubSection?.sales.filter(
      (sale) => sale.breakdownId !== deletedSale.breakdownId,
    );
    return this.getUpdatedEcommerceSalesFromUpdatedSales(
      updatedSaleArray,
      ecommerceSales,
      currentSubSection,
      currentSection,
      deletedSale,
    );
  }

  public deleteSubSectionInEcommerceSales(
    ecommerceSales: EcommerceSales,
    deletedSubSection: EcommerceSalePost,
  ): EcommerceSales | null {
    const currentSection: EcommerceSaleSection | undefined = ecommerceSales.sections.find(
      (section) => section.sectionId === deletedSubSection.sectionId,
    );
    if (!currentSection) {
      return null;
    }
    currentSection.subSections =
      currentSection.subSections?.filter((subSection) => subSection.subSectionId !== deletedSubSection.subSectionId) ??
      [];
    const updatedSectionArray: EcommerceSaleSection[] = ecommerceSales.sections.map((section) =>
      section.sectionId === currentSection.sectionId ? currentSection : section,
    );
    const updatedEcommerceSales: EcommerceSales = {
      ...ecommerceSales,
      sections: updatedSectionArray,
      totalAmount: deletedSubSection?.totalAmount ?? ecommerceSales.totalAmount,
      totalVatAmount: deletedSubSection?.totalVatAmount ?? ecommerceSales.totalVatAmount,
    };
    return updatedEcommerceSales;
  }

  private getUpdatedEcommerceSalesFromUpdatedSales(
    updatedSaleArray: EcommerceSale[],
    ecommerceSales: EcommerceSales,
    currentSubSection: EcommerceSaleSubSection,
    currentSection: EcommerceSaleSection,
    updatedSale?: EcommerceSalePost,
  ): EcommerceSales {
    const updatedSubSection: EcommerceSaleSubSection = { ...currentSubSection, sales: updatedSaleArray };
    const updatedSubSectionArray: EcommerceSaleSubSection[] = currentSection.subSections.map((sb) =>
      sb.subSectionId === updatedSubSection.subSectionId ? updatedSubSection : sb,
    );
    const updatedSection: EcommerceSaleSection = { ...currentSection, subSections: updatedSubSectionArray };
    const updatedSectionArray: EcommerceSaleSection[] = ecommerceSales.sections.map((section) =>
      section.sectionId === updatedSection.sectionId ? updatedSection : section,
    );
    const updatedEcommerceSales: EcommerceSales = {
      ...ecommerceSales,
      sections: updatedSectionArray,
      totalAmount: updatedSale?.totalAmount ?? ecommerceSales.totalAmount,
      totalVatAmount: updatedSale?.totalVatAmount ?? ecommerceSales.totalVatAmount,
    };
    return updatedEcommerceSales;
  }
}
