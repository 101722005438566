import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { EcommerceShopSynchronizeFields, EcommerceShopSynchronizeResult } from '@dougs/ecommerce/dto';

@Injectable({ providedIn: 'root' })
export class EcommerceShopSyncHttp {
  constructor(private readonly http: HttpClient) {}

  synchronize(
    salesChannelId: number,
    companyId: number,
    fields: EcommerceShopSynchronizeFields,
  ): Observable<EcommerceShopSynchronizeResult> {
    return this.http.post<EcommerceShopSynchronizeResult>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/salesChannels/${salesChannelId}/actions/connect`,
      { fields },
    );
  }
}
